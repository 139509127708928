.h-90vh {
  min-height: 95vh;
}
.image-post {
  width: 100vw;
}
.text-position {
  top: 30vh;
  left: 35vw;
}

.image-position {
  top: 20vh;
  right: 50vw;
}

.top-vh {
  top: 70vh;
}


@media (min-width: 768px) {
  .text-position {
    top: 25vh;
    left: 45vw;
  }
  .image-post {
    width: 25vw;
  }
}

@media (min-width: 640px) {
  .image-post {
    width: 50vw;
  }
}
